import _iconIos from "icon-ios";
import _iconLinux from "icon-linux";
import _iconAndroid from "icon-android";
import _iconChrome from "icon-chrome";
import _iconFirefox from "icon-firefox";
import _iconIe from "icon-ie";
import _iconOpera from "icon-opera";
import _iconOsx from "icon-osx";
import _iconSafari from "icon-safari";
import _iconWindows from "icon-windows";
var exports = {};
exports = [_iconIos, _iconLinux, _iconAndroid, _iconChrome, _iconFirefox, _iconIe, _iconOpera, _iconOsx, _iconSafari, _iconWindows];
export default exports;